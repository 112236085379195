import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://github.com/hasparus/r3fts"
      }}>{`https://github.com/hasparus/r3fts`}</a>{` `}<br parentName="p"></br>{`
`}<strong parentName="p">{`Talk duration`}</strong>{`: 35 minutes`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Dan Questions`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`How will you deliver the idea to the audience?`}</em><ul parentName="li">
          <li parentName="ul">{`R3F is pretty rad. Gonna show `}<a parentName="li" {...{
              "href": "https://i2160.csb.app/"
            }}>{`this`}</a>{` on the first slide.`}</li>
        </ul></li>
      <li parentName="ul"><em parentName="li">{`What is the one thing that you want people to take away from your talk?`}</em><ul parentName="li">
          <li parentName="ul">{`Compiler API is `}<strong parentName="li">{`powerful`}</strong>{`. We can use this power to hard solve problems.`}</li>
        </ul></li>
      <li parentName="ul"><em parentName="li">{`Why are you giving the talk? What is the emotional core? What do you believe in?`}</em><ul parentName="li">
          <li parentName="ul">{`Don’t be afraid. Contribute to open source. Hack weird stuff.`}</li>
        </ul></li>
    </ul>
    <p><strong parentName="p">{`Format`}</strong>{`: Case Study.`}</p>
    <p><strong parentName="p">{`Outline`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`what is React Three Fiber`}</li>
      <li parentName="ul">{`why I care about it`}<ul parentName="li">
          <li parentName="ul">{`(past threejs experience in Explain Everything)`}</li>
        </ul></li>
      <li parentName="ul">{`how it works`}<ul parentName="li">
          <li parentName="ul">{`what is a reconciler?`}</li>
        </ul></li>
      <li parentName="ul">{`reconciler problem — conflict with @types/react `}<a parentName="li" {...{
          "href": "https://github.com/react-spring/react-three-fiber/pull/233"
        }}>{`#233`}</a>{` `}<br parentName="li"></br>
        {`I generated components for dynamic behavior of r3f by mapping over THREE types, dumping them to a file with Compiler API.`}<ul parentName="li">
          <li parentName="ul">{`why was it hard in TS`}<ul parentName="li">
              <li parentName="ul">{`static clashes with dynamic`}</li>
            </ul></li>
          <li parentName="ul">{`How React Native lies about its types`}</li>
          <li parentName="ul">{`Why was traversing the API in JS harder (impossible?)`}</li>
          <li parentName="ul">{`show the code`}</li>
        </ul></li>
    </ul>
    <p><strong parentName="p">{`Comments`}</strong>{`:`}</p>
    <p>{`I have actually removed 1/3 of the talk (`}<a parentName="p" {...{
        "href": "https://gist.github.com/hasparus/bcd582d93d5892f54a18607fe54afb4b/edit"
      }}>{`initial idea gist`}</a>{`) on the same day I gave it.
It was a good idea. The talk took the time it was meant to.`}</p>
    <p>{`Started with the tease, went ahead to explain the problem and
continued with the solution. Why -> What -> How. Got good feedback
on the flow.`}</p>
    <p>{`I think it resonated pretty well.
However, after watching the video I’m a bit unhappy with the delivery.
The deck could also be improved.
I might polish it and try to fit more story and more knowledge in a bit longer time window.
I think I’ll leave rollup plugin story for another talk though, to keep this more focused on React and TS Compiler API.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      